// bootstrap class overwrite css
:root {
  --primary-color: linear-gradient(#622dd3, #651ab1, #6818b1, #6c14b1, #6b23e2);
  --secondary-color: #f19828;

  --chart-color1: #484c7f;
  --chart-color2: #f19828;
  --chart-color3: #f5558d;
  --chart-color4: #a7daff;
  --chart-color5: #9cbf3b;
  --chart-color6: #a0c1b8;
  --chart-color7: #64868e;
  --feedback-color: #E5B5F7;
  --librarian-color: #C9EBFF;
  --primary-gradient: linear-gradient(45deg, #f5558d, #ff1843);
}

.border,
.border-top,
.border-right,
.border-bottom,
.border-left,
.dropdown-divider {
  border-color: var(--border-color);
}
.container-xxl {
  max-width: 1560px;
}
.font-weight-bold {
  font-weight: bold;
}
.table > :not(caption) > * > * {
  padding: 0.8rem 0.8rem;
}
.zindex-fixed {
  z-index: $zindex-fixed;
}
.zindex-popover {
  z-index: $zindex-popover;
}
.zindex-modal {
  z-index: $zindex-modal;
}
.small-xs {
  font-size: 13px !important;
}

// .form-control
.form-control,
.form-select {
  border-color: var(--border-color);
  background-color: var(--color-on-primary-60);
  color: var(--text-color);
  font-size: 16px;
  &::placeholder {
    color: var(--color-400);
  }
  &:focus {
    box-shadow: 0 0 10px rgba($dark, 0.15);
    color: var(--text-color);
    background-color: var(--color-on-primary-60);
  }

  &.form-control-lg {
    min-height: calc(1.5em + 1.8rem + 2px);
  }
}
.input-group-text {
  border-color: var(--border-color);
  background-color: var(--color-200);
  color: var(--text-color);
}
// form Switches css
.form-switch {
  .form-check-input {
    &:checked {
      background-color: var(--primary-color);
      border-color: var(--border-color);
    }
  }
}
.big-search {
  color: var(--color-fff);
  &:focus {
    color: var(--color-fff);
  }
}
.btn-link {
  color: var(--text-color);
  &:hover {
    color: var(--secondary-color);
  }
}
.bg-white {
  color: var(--text-color);
}
//lineheight
.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
  line-height: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
  line-height: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
  line-height: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
  line-height: calc(1.275rem + 0.3vw) !important;
}

.btn {
  transition: all 0.2s ease;
  font-size: $font-size;
  padding: 10px 12px;
  color: var(--text-color);
  border-radius: 8px;

  &.btn-primary {
    background: var(--color-primary);
    color: var(--white-color);
    border: none;
    &.active {
      background: var(--secondary-color);
    }
    --bs-btn-active-bg: var(--color-primary);
  
  }
  &.btn-white {
    background-color: var(--color-fff);
    border-color: var(--color-fff);
    color: var(--color-700);
  }
  &.btn-dark {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: var(--white-color);
  }
  &.btn-secondary {
    color: var(--white-color);
  }
  &.btn-danger {
    color: var(--white-color);
  }

  &.btn-outline-primary {
    --bs-btn-active-bg: var(--color-primary);
    --bs-btn-active-border-color: var(--color-primary);
    --bs-btn-border-color: var(--color-primary);
    color: var(--color-primary);
    background: transparent;
    border: 1px solid var(--color-primary);
    &:hover {
      color: $white;
      background-color: var(--color-primary);
    }
    &.active {
      background-color: var(--primary-color);
      color: var(--white-color);
    }
  }
  &.btn-outline-secondary {
    border-color: var(--border-color);
    &:hover {
      color: $white;
      background-color: var(--secondary-color);
      i {
        color: $white !important;
      }
    }
  }
  &.btn-outline-success {
    color: $color-success;
    border-color: $color-success;
    &:hover {
      background-color: $color-success;
      color: $white;
    }
  }
  &.btn-outline-danger {
    color: $color-danger;
    border-color: $color-danger;
    &:hover {
      background-color: $color-danger;
      color: $white;
    }
  }
  &.btn-outline-info {
    color: $color-info;
    border-color: $color-info;
    &:hover {
      background-color: $color-info;
      color: $white;
    }
  }

  &.btn-lg {
    padding: 0.7rem 1rem;
  }
}

.btn-close {
  color: var(--text-color);
}

// avatar
.avatar {
  width: 34px;
  min-width: 34px;
  height: 34px;

  &.xs {
    width: 16px;
    min-width: 16px;
    height: 16px;
  }
  &.sm {
    width: 22px !important;
    min-width: 22px !important;
    height: 22px !important;
    i {
      font-size: 12px;
      line-height: 12px;
      vertical-align: text-top;
    }
  }
  &.lg {
    width: 56px;
    min-width: 56px;
    height: 56px;
  }
  &.xl {
    width: 120px;
    min-width: 120px;
    height: 120px;
  }
  &.no-thumbnail {
    background-color: var(--color-300);
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.avatar-list {
  margin: 0 0 -0.5rem;
  padding: 0;
  font-size: 0;
  .avatar {
    color: var(--secondary-color);
    font-weight: 600;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    border-radius: 50%;
    display: inline-block;
    background: var(--primary-color) no-repeat center/cover;
    position: relative;
    vertical-align: bottom;
    font-size: 0.875rem;
    user-select: none;
    min-width: 2rem;
    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
  &.list-inlineblock {
    li {
      display: inline-block;
    }
  }
}
.avatar-list-stacked {
  .avatar {
    margin-right: -0.8em !important;
    box-shadow: 0 0 0 2px $white;
  }
}
.header {
  .avatar-list-stacked {
    @media only screen and (max-width: $break-xsmall - 150px) {
      display: none;
    }
  }
}
.add-member-top {
  @media only screen and (min-width: $break-xsmall - 150px) {
    display: none;
  }
}
// dropdown menu
.dropdown-item:hover,
.dropdown-item:focus {
  background-color: rgba($dark, 0.1);
}
.dropdown-item {
  color: var(--text-color);
  &:hover {
    color: var(--secondary-color);
  }
}
.dropdown-menu {
  font-size: $font-size + 1px;
  color: var(--text-color);
  background-color: var(--body-color);
  li {
    margin: 5px 0px;
  }
  .dropdown-item {
    color: var(--text-color);
    &.active {
      color: var(--secondary-color);
      background-color: var(--primary-color);
    }
    &:active,
    &:focus {
      background-color: var(--primary-color);
      color: var(--secondary-color);
    }
    &:hover {
      color: var(--secondary-color);
    }
  }
}

// custom modal popup css
.modal {
  right: 0;
  left: auto;
  z-index: 99999;

  &.fade {
    .modal-dialog-vertical {
      transform: translateX(-100%);
    }
  }
  &.show {
    .modal-dialog-vertical {
      transform: translateX(0);
    }
  }
  .modal-dialog-vertical {
    margin: 0;
    height: 100%;
    .modal-content {
      height: 100%;
    }
  }
  .modal-header,
  .modal-footer {
    border-color: var(--border-color);
  }
  .modal-content {
    background: var(--body-color);
  }
}

// breadcrumb
.breadcrumb {
  background-color: var(--color-200);
}

// navbar
.navbar-light {
  .navbar-nav {
    .nav-link {
      color: var(--color-500);
      &:hover,
      &:focus {
        color: var(--primary-color);
      }
    }
  }
}

// custom tab ui
.nav-tabs {
  border-color: var(--border-color);
  &.tab-custom {
    border: 1px solid rgba($white, 0.2);
    .nav-link {
      color: $white;
      opacity: 0.7;
      &.active {
        opacity: 1;
        color: var(--primary-color);
      }
    }
  }
  &.tab-card {
    .nav-link {
      border-top: 0;
      border-left: 0;
      border-right: 0;
      background-color: transparent;
      border-bottom: 3px solid transparent;
      color: var(--color-500);

      &.active {
        border-color: var(--primary-color);
      }
    }
  }
  &.tab-body-header {
    border: 1px solid Var(--primary-color);
    overflow: hidden;
    .nav-link {
      border: 0;
      margin: 0;
      border-radius: 0;
      color: var(--color-500);

      &:hover {
        color: var(--primary-color);
      }

      &.active {
        background-color: var(--primary-color);
        color: $white;
        margin-left: -1px;
      }
    }
  }
}

// nav pill
.nav-pills {
  .nav-link.active,
  .show > .nav-link {
    background-color: var(--primary-color);
    color: var(--color-primary);
    font-weight: 700;
    border-bottom: 2px solid var(--color-primary);
    border-radius: 0;
  }
  .nav-link {
    border-radius: 0;
    color: var(--text-color);
    border-bottom: 2px solid var(--color-primary-30);
  }
}
//border
.border-dark {
  border-color: var(--text-color) !important;
}
.border-start {
  border-color: var(--border-color) !important;
}
// list-group design
.list-group-custom,
.list-group {
  .list-group-item {
    background-color: var(--card-color);
    border-color: var(--border-color);
    color: var(--text-color);
    &.active {
      background-color: var(--primary-color);
    }
  }
}

// .progress bar design
.progress {
  background-color: var(--color-400);
  border-radius: 15px;
  &.plan-active {
    background-color: var(--color-500);
  }
}
.progress-stacked {
  background: transparent;
}

// table
.table {
  border-color: var(--border-color);
  tr {
    vertical-align: middle;
    th {
      // border-color: var(--text-color);
      color: var(--text-color);
      text-transform: uppercase;
      font-size: 12px;
    }
    td {
      border-color: var(--border-color);
      color: var(--text-color);
    }
    &:hover {
      td {
        color: var(--color-800);
      }
    }
  }
  .table-dark {
    tr {
      th {
        color: var(--white-color);
      }
    }
  }
  &.doc-table {
    tr {
      th {
        border-color: var(--border-color);
      }
    }
  }
}

// pagination
.page-item {
  &.disabled {
    .page-link {
      background-color: var(--border-color);
      border-color: var(--border-color);
    }
  }
  .page-link {
    background-color: var(--card-color);
    border-color: var(--border-color);
    color: var(--primary-color);
  }
  &.active {
    .page-link {
      background-color: var(--primary-color);
      border-color: var(--primary-color);
    }
  }
}

// CAI icon animation
.cognent_logo {
  position: relative;
  width: 50px;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.cognent_logo .logo__lines {
  position: relative;
  z-index: 2;
  width: 64%;
  height: 64%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 0;
}

.cognent_logo svg {
  width: 50%;
  height: 50%;
}

.cognent_logo .logo__line-b,
.cognent_logo .logo__line-d {
  transform: scale(-1, 1);
}

.cognent_logo .logo__line-a path,
.cognent_logo .logo__line-b path {
  d: path(
    'M8 192C15.5 192 25.5 192 34 192C42.5 192 50 192 56 192C63.0002 185 75.5 172.5 90.5 157.5C101.5 146.5 102.5 145.5 116.5 131.5C130.5 117.5 157.5 90.5001 170 78.0001C182.5 65.5001 188 60.0001 192 56.0001C192 51.5001 192 46.0001 192 40.5001C192 35.0002 192 32.0001 192 26.5001C192 20.0002 192 13.5001 192 8.00012C186 13.5001 121 78.5001 107.5 92.5001C96.4536 103.956 74.5002 125.5 64.0002 136C54.0002 146 49 151 40.5 159.5C33.2001 166.8 12 188 8 192Z'
  );
}

.cognent_logo .logo__line-c path,
.cognent_logo .logo__line-d path {
  d: path(
    'M8 8.00004C15.5 8.00004 25.5 7.99995 34 8.00004C42.5 8.00012 50 8 56 8C63.0002 15 75.5 27.5001 90.5 42.5001C101.5 53.5001 102.5 54.5 116.5 68.5C130.5 82.5 157.5 109.5 170 122C182.5 134.5 188 140 192 144C192 148.5 192 154 192 159.5C192 165 192 168 192 173.5C192 180 192 186.5 192 192C186 186.5 121 121.5 107.5 107.5C96.4536 96.0445 74.5002 74.5001 64.0002 64.0001C54.0002 54.0001 49 49 40.5 40.5C33.2001 33.2001 12 12 8 8.00004Z'
  );
}

.cognent_logo .logo__center {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  width: 32%;
  height: 32%;
  margin: auto;
}

.cognent_logo .logo__gear {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.cognent_logo .logo__center,
.cognent_logo .logo__center path,
.cognent_logo .logo__gear {
  transform-origin: center center;
  transition: 1.2s ease-in;
}

.cognent_logo .logo__line-a path,
.cognent_logo .logo__line-b path,
.cognent_logo .logo__line-c path,
.cognent_logo .logo__line-d path {
  transition: 0.4s ease-in 0.8s;
}

.cognent_logo.animate .logo__line-a path,
.cognent_logo.animate .logo__line-b path {
  transition: 1s ease-out 0.15s;
  d: path(
    'M25.5002 71.9999C29.5002 76.9999 30.5002 79 35.0002 84.5C39.5002 90 44.5 96.5 48.5 101C56 99 66 95.5 76 92.5C82.8886 90.4334 102 84 110.5 81.5C119 79 130.775 75.0095 137.5 73C144.226 70.9904 157 66.5 160.5 65.5C161 61 161 59.5 161.5 55C162 50.5 162.09 48.0091 162.5 43.5C163.021 37.769 163.5 34.5 164 28.5C158 30.5 151.5 32.5833 141.5 35.4999C126.925 39.751 112.165 44.5934 104 46.9999C91.19 50.7756 82.5002 53.9999 71.0002 57.4999C59.5002 60.9999 31.7541 69.9655 25.5002 71.9999Z'
  );
}

.cognent_logo.animate .logo__line-c path,
.cognent_logo.animate .logo__line-d path {
  transition: 1s ease-out 0.3s;
  d: path(
    'M33.5 77C39.5 73 48.5752 67.1165 52.5 64.5C57 61.5 62.5 57.5 65 56C73 61 99.6574 77.0359 106 81C114.5 86.3125 131 96.5 137.5 100.5C143.416 104.14 164.632 116.779 170 120C180 126 195 135.5 201 139C201 142 201 151 201 158C201 165 201 157 201 162.5C201 167.698 201 171.5 201 179.5C194.5 175.5 174 163 161.5 155.5C156.01 152.206 141 143 129 135.5C117 128 94 114 81.5 106.5C72.6476 101.189 40 81 33.5 77Z'
  );
}

.cognent_logo.animate .logo__center path {
  transition: 2s ease-out;
  transform: rotate(360deg) scale(0.3);
}

.cognent_logo.animate .logo__center {
  transition: 1.4s ease-out 0.4s;
  transform: translateY(-10%);
}

.cognent_logo.animate .logo__gear {
  transition: 2s ease-out;
  transform: rotate(360deg);
}

.cognent_ai_thinking_container {
  display: flex;
  align-items: center; /* Align items vertically in the center */
  padding-bottom: 50px;
  padding-right: 1px;
}

.cognent_ai_thinking_container .cognent_ai_logo {
  width: 30px;
  height: 30px;
  animation: cognent_ai_logo_spin 4s linear infinite;
}

@keyframes cognent_ai_logo_spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.cognent_ai_thinking_container .cognent_ai_logo_flip {
  width: 40px;
  height: 40px;
  animation: cognent_ai_logo_flip 4s linear infinite;
  transform-style: preserve-3d; /* Ensure 3D transformations are applied */
  transform-origin: 50% 50%; /* Spin around the central axis */
}

@keyframes cognent_ai_logo_flip {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

.cai-chat-popup {
  position: fixed;
  bottom: 20px; /* Distance from the bottom */
  right: 20px; /* Distance from the right */
  width: 400px; /* Width of the popup */
  height: 700px; /* Height of the popup */
  background-color: #fff; /* Background color */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Adds shadow for depth */
  z-index: 1000; /* Ensure it sits above other content */
  overflow: hidden; /* Ensures content doesn't spill out */
  border-radius: 8px; /* Optional: Rounds the corners */
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
}

// mentor activity
.panel {
  width: 100%;
  height: 500px;
  background-color: var(--color-surface);
  padding: 30px;
  border-radius: 12px;
  box-shadow: var(--chat-shadow);
  font-family: Arial, sans-serif;
}

.panel h1 {
  color: var(--text-color);
  font-size: 26px;
  margin-bottom: 30px;
}

.panel ol {
  list-style-type: none;
  padding: 0;
}

.panel ol li {
  background-color: var(--color-surface);
  padding: 10px 15px;
  margin-bottom: 10px;
  border-radius: 8px;
  cursor: pointer;
  transition:
    background-color 0.3s,
    box-shadow 0.3s;
}

.panel ol li:hover {
  background-color: var(--color-surface);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.fc .fc-h-event {
  background: var(--primary-color);
}

.hover-disable {
  &:hover {
    color: $white !important;
    background-color: transparent !important;
  }
}

.chat-message p {
  margin-bottom: 0;
}

.border {
  &.border-primary {
    border-color: var(--color-primary) !important;
  }
}


.glow {
  filter: drop-shadow(0 0 8px rgba(139, 0, 0, 0.8)); /* Darker red */
}

@keyframes pulse-alert {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5); 
  }
  100% {
    transform: scale(1);
  }
}
.pulse-alert {
  animation: pulse-alert 1.2s infinite ease-in-out; 
}
